<template>
  <div>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-lg-9">
          <div class="card mb-3">
            <div class="card-header">
                <h3 class="mb-0">Card Manager</h3>
              </div>

            <div class="card-body min-vh" v-loading="loading">
              <div class="row mb-4 pb-4 border-bottom" v-for="item in cardList" :key="item.uid">
                <div class="col-md-6"><PlusCard  :cardData="item"/></div>
                <div class="col-md-6">
                  <div class="d-flex flex-column">
                    <h6 class="mb-3 text-sm">{{ item.currency }}</h6>
                    <span class="mb-2 text-xs">Status: <el-tag effect="dark" size="mini" :type="item.card_status ==='active' ? 'success' : 'danger'">{{ item.card_status.toUpperCase()}}</el-tag></span>
                    <span class="mb-2 text-xs">Bank Holder: <b>{{ item.bankholderID }}</b></span>
                    <span class="mb-2 text-xs">Card Name: <b>{{ item.name.toUpperCase() }}</b></span>
                    <span class="mb-2 text-xs">Card Number: <b>{{ item.PAN }}</b></span>
                    <span class="mb-2 text-xs">Card Expire: <b>{{ item.expire_date }}</b></span>
                    <div>
                      <el-button v-if="['issued'].includes(item.card_status)" type="primary" size="small" class="btn" @click="setActivate(item.card_id)">Activate</el-button>
                      <el-button v-if="['blocked'].includes(item.card_status)" type="primary" size="small" class="btn" @click="setStatus(item.card_id, item.card_status, 'active')">Active</el-button>
                      <el-button v-if="['active'].includes(item.card_status)" type="danger" size="small" class="btn" @click="setStatus(item.card_id, item.card_status, 'blocked')">Block</el-button>
                      <el-button v-if="['active', 'blocked'].includes(item.card_status)" type="success" size="small" class="btn" @click="getPin(item.card_id)">PIN</el-button>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>

        <div class="col-lg-3">
          <div class="card mb-3">
              <div class="card-body">
                <Shortcut />
              </div> 
          </div>

        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
import PlusCard from "@/components/PlusCard";
import { getCardList, getPin, setStatus, setActivate } from '@/api/card'
import Shortcut from '@/components/MenuShortcut.vue'
export default {
  components:{
    PlusCard,
    Shortcut
  },
  computed:{
    getname(){
      return this.$store.getters.name
    },
    multiaccount(){
      return this.$store.getters.multiaccount
    },
    isBusiness(){
      return this.$store.getters.business
    }
  },
  data(){
    return{
      loading: false,
      cardList:[]
    }
  },
  methods:{
    getCard(cb){
      this.loading = true
      getCardList().then(({result, data})=>{
        this.loading = false
        if(result){
          this.cardList = data.actived
        }
        if(typeof cb === 'function'){
          cb();
        }
      })
    },
    setActivate(card_id){
      this.$swal({
          title: 'Confirmation',
          html: `Activate Card. Continue?`,
          icon: 'success',
          showCancelButton: true,
          confirmButtonText: 'Activate',
          cancelButtonText: 'Cancel',
          showCloseButton: true,
          showLoaderOnConfirm: true,
          reverseButtons: true
        }).then((result) => {
          if(result.value) {
            this.setActivateApi(card_id)
          } else {
            this.$message('Activate Card Canceled'); 
          }
        })
    },
    setActivateApi(card_id){
      setActivate(card_id).then(({result, data, message})=>{
        if(result) {
            this.getCard(()=>{
              this.$swal(data.pin, `You card PIN`, 'success')
            })
            
          } else {
            this.$swal('Message', message, 'error')
          }
      })
    },
    setStatus(card_id, from_status, to_status){
      const okText = to_status === "blocked" ? "Block" : "Active";
      const confirmBtnColor =  to_status === "blocked" ? '#d33' : '#3085d6' 
      this.$swal({
          title: 'Update card status',
          html: `This will change card status <b>${from_status}</b> to <b>${to_status}</b>. Continue?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: confirmBtnColor,
          confirmButtonText: okText,
          cancelButtonText: 'Cancel',
          showCloseButton: true,
          reverseButtons: true
        }).then((result) => {
          if(result.value) {
            this.setStatusApi(card_id, to_status)
          } else {
            this.$message(`Set Status ${from_status} to ${to_status} Canceled`); 
          }
        })
    },
    setStatusApi(card_id, status){
      setStatus(card_id, { status : status}).then(({result, message})=>{
        if(result){
          this.getCard()
        }else{
          this.$message.error(message);
          this.$swal('Message', message, 'error')
        }
      })
    },
    getPin(card_id){
      const loading = this.$notifyloading("Get PIN...");
      getPin(card_id).then(({result, data, message})=>{
        loading.close()
        if(result){
          this.$swal(data.pin, `You card PIN`, 'success')
        }else{
          this.$swal('Message', message, 'error')
        }
      })
    }
  },
  created(){
    this.getCard()
  }
};
</script>
<style></style>
