import request from './request'
import {uuid} from 'vue-uuid'

export function getCardList(){
  return request({
    url: `/v1/card`,
    method: "get"
  });
}

export function getPin(card_id){
  return request({
    url: `/v1/card/${card_id}/pin/get`,
    method: "get"
  });
}

export function setStatus(card_id, data){
  data.idempotency = uuid.v1()
  return request({
    url: `/v1/card/${card_id}/status`,
    method: "post",
    data: data
  });
}

export function setActivate(card_id){
  return request({
    url: `/v1/card/${card_id}/activate`,
    method: "post",
    data: { idempotency : uuid.v1()}
  });
}

export function cardRequest(data){
  data.idempotency = uuid.v1()
  return request({
    url: `/v1/card/request`,
    method: "post",
    data: data
  });
}

