<template>
  <div class="plus-card-content">
    <div class="plus-card">
      <svg
          width="100%"
          height="100%"
          viewBox="0 0 800 502"
          preserveAspectRatio="xMinYMid meet"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
        <image :href="src" height="100%" width="100%"/>
        <text
          x="50"
          y="310"
          font-size="3.2rem"
          font-weight="bold"
          fill="#606266"
        >{{cardNumber ? cardNumber.toUpperCase() : ""}}</text>
        <text
          x="50"
          y="470"
          font-size="2rem"
          font-weight="bold"
          fill="#606266"
        >{{cardName ? cardName.toUpperCase() : ""}}</text>
        <text
          x="350"
          y="385"
          font-size="1.8rem"
          font-weight="bold"
          fill="#606266"
        >{{expireDate ? expireDate.toUpperCase() : ""}}</text>
      </svg>

    </div>
  </div>
</template>

<script>

export default {
  props:{
    cardData: Object
  },
  data(){
    let pluscard = 'img/card/'+ this.cardData.card_image;
    return {
      src: pluscard,
      cardName: this.cardData.name,
      cardNumber: this.cardData.PAN,
      expireDate: this.cardData.expire_date
    }
  }
}
</script>

<style lang="scss">
.plus-card-content{
  .plus-card{
    position: relative;
    width: 70%;
    transform: translateX(20%);

    svg{
      box-shadow: 1px 2px 7px rgba(0, 0, 0 , .12);
      border-radius: 10px;
    }

  }
}
</style>